import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponseModel } from "src/app/models/common.model";
import {
  AccountDetail,
  GetLoanCountAndAssignedToResult,
  InitiateLoanDetails,
  Loan,
  LoanFilterData,
  LoanRequestBody,
  LoanRequestData,
  LoanStatusUpdate,
  NewBankAccount,
} from "src/app/store/loan/loan.types";
import { TokenStorageService } from "../TokenStorageService/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class LoanService {
  private apiUrl: string;
  idToken: string;
  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    @Inject("BASE_URL") private baseUrl: string
  ) {
    this.apiUrl = `${this.baseUrl}api/`;
    this.idToken = tokenStorageService.getAccessToken() || "";
  }

  // Loan Creation
  createLoan(loanDetails: InitiateLoanDetails): Observable<ApiResponseModel<InitiateLoanDetails>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<InitiateLoanDetails>>(`${this.apiUrl}Loan`, loanDetails, { headers });
  }

  createBank(
    bankAccountDetails: NewBankAccount
  ): Observable<ApiResponseModel<boolean> | ApiResponseModel<AccountDetail[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<boolean>>(`${this.apiUrl}Loan/BankAccount`, bankAccountDetails, { headers });
  }

  getLoadById(loanId: string): Observable<ApiResponseModel<InitiateLoanDetails>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<InitiateLoanDetails>>(`${this.apiUrl}Loan/${loanId}`, { headers });
  }

  deleteBankAccountById(bankAccountId: string): Observable<ApiResponseModel<boolean>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.delete<ApiResponseModel<boolean>>(`${this.apiUrl}Loan/BankAccount/${bankAccountId}`, { headers });
  }

  // Loan Listing
  getLoans(loan: LoanRequestData): Observable<ApiResponseModel<Loan[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<Loan[]>>(`${this.apiUrl}Loan/Data`, loan, { headers });
  }

  getLoanFiltersData(appliedFilters: LoanRequestBody): Observable<ApiResponseModel<LoanFilterData>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<LoanFilterData>>(`${this.apiUrl}Loan/Filter`, appliedFilters, { headers });
  }

  getLoanCountAndAssignedTo(userId: string): Observable<ApiResponseModel<GetLoanCountAndAssignedToResult>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<GetLoanCountAndAssignedToResult>>(
      `${this.apiUrl}StatementTransaction/LoanCountAndAssignedTo?userId=${userId}`,
      { headers }
    );
  }

  assignNewLoan(userId: string): Observable<ApiResponseModel<GetLoanCountAndAssignedToResult>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<GetLoanCountAndAssignedToResult>>(
      `${this.apiUrl}StatementTransaction/AssignLoanToUser/${userId}`,
      { headers }
    );
  }

  updateLoanStatus(loanStatusUpdate: LoanStatusUpdate): Observable<ApiResponseModel<LoanStatusUpdate>> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post<ApiResponseModel<LoanStatusUpdate>>(
      this.baseUrl + "api/StatementSummary/UpdateLoanStatus/",
      loanStatusUpdate,
      { headers }
    );
  }
}
