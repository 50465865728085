import { ActionType, createAction, props } from "@ngrx/store";
import { AccountType, BuisnessType } from "src/app/models/loans";
import { AppConfigState } from "../app.states";

export const GET_APP_CONFIG = "GET_APP_CONFIG";
export const GET_APP_CONFIG_SUCCESS = "GET_APP_CONFIG_SUCCESS";
export const GET_APP_CONFIG_FAILURE = "GET_APP_CONFIG_FAILURE";

export const GetAppConfig = createAction(GET_APP_CONFIG);
export const GetAppConfigSuccess = createAction(GET_APP_CONFIG_SUCCESS, props<{ appConfig: AppConfigState }>());
export const GetAppConfigFailure = createAction(GET_APP_CONFIG_FAILURE, props<{ error: string }>());

export const GET_BUISNESS_TYPES = "GET_BUISNESS_TYPES";
export const GET_BUISNESS_TYPES_SUCCESS = "GET_BUISNESS_TYPES_SUCCESS";
export const GET_BUISNESS_TYPES_FAILURE = "GET_BUISNESS_TYPES_FAILURE";

export const GetBuisnessTypes = createAction(GET_BUISNESS_TYPES);
export const GetBuisnessTypesSuccess = createAction(GET_BUISNESS_TYPES_SUCCESS, props<{ data: BuisnessType[] }>());
export const GetBuisnessTypesFailure = createAction(
  GET_BUISNESS_TYPES_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const GET_ACCOUNT_TYPES = "GET_ACCOUNT_TYPES";
export const GET_ACCOUNT_TYPES_SUCCESS = "GET_ACCOUNT_TYPES_SUCCESS";
export const GET_ACCOUNT_TYPES_FAILURE = "GET_ACCOUNT_TYPES_FAILURE";

export const GetAccountTypes = createAction(GET_ACCOUNT_TYPES);
export const GetAccountTypesSuccess = createAction(GET_ACCOUNT_TYPES_SUCCESS, props<{ data: AccountType[] }>());
export const GetAccountTypesFailure = createAction(
  GET_ACCOUNT_TYPES_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export type CommonActions =
  | ActionType<typeof GetBuisnessTypes>
  | ActionType<typeof GetBuisnessTypesSuccess>
  | ActionType<typeof GetBuisnessTypesFailure>
  | ActionType<typeof GetAppConfig>
  | ActionType<typeof GetAppConfigSuccess>
  | ActionType<typeof GetAppConfigFailure>
  | ActionType<typeof GetAccountTypes>
  | ActionType<typeof GetAccountTypesSuccess>
  | ActionType<typeof GetAccountTypesFailure>;
